<template>
    <div
            class="data-page-content rowFlex"
            style="padding: 22px 38px 30px 38px; justify-content: center"
    >
        <div class="data-group">
            <section
                    class="data-item video"
                    v-for="item in droneList"
                    :key="item.droneId"
            >
                <div class="title">{{ item.airportName }}-{{ item.droneName }}</div>
                <div class="content">
                    <template v-if="item.droneState !== 0">
                        <div class="drone-video">
                            <div class="ali-player" :id="`drone_video_${item.droneId}`"/>
                        </div>

                        <div class="status-panel">
                            <div>
                                无人机当前电量：{{ item.b != undefined ? item.b : "-" }}%
                                <span class="status-label">{{
                  droneState[item.droneState]
                }}</span>
                            </div>
                            <div>当前高度：{{ item.h != undefined ? item.h : "-" }}米</div>
                            <div>
                                当前速度：{{ item.sp != undefined ? item.sp : "-" }}米/秒
                            </div>
                        </div>
                        <div class="flight-map">
                            <img :src="item.waypointPicture" alt=""/>
                        </div>
                        <div class="flight-img">
                            <img v-for="src in item.pictures" :src="src" :key="src" alt=""/>
                        </div>
                        <!--                                                <div class="drones-tip">
                                                                打开机罩
                                                            </div>-->
                    </template>
                    <div
                            v-else
                            style="
              font-size: 40px;
              position: absolute;
              top: calc(50% - 20px);
              left: 50%;
              transform: translate(-50%, -50%);
              color: #00fff4;
            "
                    >
                        暂无画面
                    </div>
                </div>
            </section>
        </div>
        <div class="data-group">
            <div class="data-item video">
                <div class="title">-</div>
                <div class="content">
                    <div
                            style="
              font-size: 40px;
              position: absolute;
              top: calc(50% - 20px);
              left: 50%;
              transform: translate(-50%, -50%);
              color: #00fff4;
            "
                    >
                        暂无画面
                    </div>
                </div>
            </div>
            <div class="data-item control">
                <DataItemPanelLeftBorder/>
                <div class="container">
                    <div class="title">控制台</div>
                    <div class="content">
                        <div
                                class="drones-item"
                                v-for="item in droneList"
                                :key="item.droneId"
                        >
                            <div>
                                <div class="drone-img">
                                    <img src="@/assets/bigData/drones/drone-img.png" alt=""/>
                                </div>
                                <span>{{ item.droneName }}</span>
                                <div class="flight-label" v-if="item.droneState !== 0">
                                    {{ droneState[item.droneState] }}
                                </div>
                            </div>
                            <!--                            item.droneState === 1  机场关闭状态   上次为返航状态-->
                            <template v-if="item.droneState === 1&&!item.isClose">
                                <!--                                //关闭状态item.airportIsOpen == 1-->
                                <div v-if="item.deviceId&&item.airportIsOpen == 1">
                                    <div
                                            class="flight-btn"
                                            @click="operationAirport(item,0)"
                                            style="line-height: 50px"
                                    >
                                        打开机场
                                    </div>
                                </div>

                                <div v-else-if="item.deviceId&&item.timeN"
                                     class="flight-btns"
                                     :class="{'noPoi':item.droneState == 0}">
                                    <TimerText :tiem='item.timeN' text1='正在开启机场' text2='机场已开启' :id="item.airportId"
                                               @timeOutFun="timeOutFun"/>
                                </div>


                                <div
                                        v-else
                                        class="flight-btn"
                                        @click="operationDrone(item)"
                                >
                                    确认飞行
                                </div>
                            </template>
                            <!--                            机舱为开启状态-->
                            <template v-else-if="item.deviceId&&item.airportIsOpen === 0&&item.droneState == 1&&item.isClose">
                                <TimerText :tiem='item.timeN' text1='正在关闭机场' text2='机场已关闭' :id="item.airportId"
                                           :state="1"
                                           @timeOutFund="timeOutFund" text3='无人机已着陆'/>
                            </template>


                            <div
                                    :class="['flight-btn', { gray: callbackCountdown > 0 }]"
                                    v-if="item.droneState === 2"
                                    @click="cancelFlight(item.droneId)"
                            >
                                返航降落
                            </div>


                            <div v-if="![1, 2].includes(item.droneState)" class="flight-btns"
                                 :class="{'noPoi':item.droneState == 0}">
                                <!--                                <template v-if="item.airportIsOpen === 0&&item.droneState == 1">-->
                                <!--                                    <TimerText :tiem='item.timeN' text1='正在关闭机场' text2='机场已关闭' :id="item.airportId"-->
                                <!--                                               :state="1"-->
                                <!--                                               @timeOutFun="timeOutFund" text3='无人机已着陆'/>-->
                                <!--                                </template>-->
                                <!--                                <template v-else>-->
                                {{
                                item.droneState !== 0
                                ? {
                                0: "未连接",
                                1: "等待飞行",
                                2: "飞行中",
                                3: "正在返航",
                                4: "异常",
                                }[item.droneState]
                                : "暂无"
                                }}
                                <!--                                </template>-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item control">
                <DataItemPanelLeftBorder/>
                <div class="container">
                    <div class="title">最新飞行记录</div>
                    <div class="content">
                        <ul class="flight-log-thead">
                            <li style="width: 110px">
                                起飞时间
                                <img src="../../assets/bigData/drones/lower.png" alt=""/>
                            </li>
                            <li style="width: 110px">
                                降落时间
                                <img src="../../assets/bigData/drones/lower.png" alt=""/>
                            </li>
                            <li style="width: 80px">
                                无人机
                                <img src="../../assets/bigData/drones/lower.png" alt=""/>
                            </li>
                            <li style="width: 80px">
                                飞行任务
                                <img src="../../assets/bigData/drones/lower.png" alt=""/>
                            </li>
                            <li style="width: 30px">
                                航点
                                <img src="../../assets/bigData/drones/lower.png" alt=""/>
                            </li>
                        </ul>
                        <div class="swiper-flight-log">
                            <div class="swiper-flight-log-wrapper">
                                <div :class="['swiper-slide swiper-no-swiping']">
                                    <ul v-for="log in flightLogs" :key="log.id">
                                        <li style="width: 110px">{{ log.takeOffTime }}</li>
                                        <li style="width: 110px">{{ log.landingTime }}</li>
                                        <li style="width: 80px">{{ log.droneName }}</li>
                                        <li style="width: 80px">
                                            {{ log.taskName }}
                                        </li>
                                        <li style="width: 30px">
                                            {{ log.wayPonits ? JSON.parse(log.wayPonits).length : 0 }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
    import {
        droneGoHomeUrl,
        getDroneRVCAddrUrl,
        queryDroneBigDataUrl,
        queryFlightLogUrl,
        startDroneFlightUrl,
        sendCommandUrl
    } from "@/requestUrl";
    import dayjs from "dayjs";
    import {initWs} from "@/views/BigData/tools";
    import TimerText from "@/views/BigData/TimerText";
    import OSS from "ali-oss";
    import CookieUtils from "@/utils/CookieUtils";
    import CoolDialog from "@/views/SmartPark/CoolDialog";

    const droneState = {
        0: "未连接",
        1: "等待飞行",
        2: "飞行中",
        3: "返航中",
        4: "异常",
    };

    const isEdge = window.navigator.userAgent.toLocaleUpperCase().includes("EDG");

    export default {
        name: "BigDataPageTwo",
        components: {DataItemPanelLeftBorder, TimerText},
        data() {
            return {
                callbackF: false,
                droneList: [],
                flightLogs: [],
                droneState,
                socket: null,
                callbackCountdown: 0,
                // 存储临时stsToken
                stsTokenStorage: {},
            };
        },
        beforeDestroy() {
            if (this.socket) {
                this.socket.close(3000, "页面关闭");
            }
        },
        mounted() {
            this.$nextTick(() => {
                this.initPage();
            });
        },
        methods: {
            //计时结束
            timeOutFun(e) {
                console.log('事假抛出');
                // this.queryDrones();
                this.droneList.forEach((item, index) => {
                    if (item.airportId == e) {
                        console.log('重新赋值');
                        console.log(e);
                        this.$set(this.droneList[index], 'timeN', false);
                    }
                })
            },
            timeOutFund() {
                console.log('事假抛出2');
                // this.droneList.forEach((item, index) => {
                //     if (item.airportId == e.id) {
                //         console.log('重新赋值');
                //         console.log(e);
                //         this.$set(this.droneList[index], 'airportIsOpen', e.state);
                //     }
                // });
                //重新查询
                setTimeout(() => {
                    this.queryDrones();
                },200)

            },
            //打开机场
            operationAirport(item, state) {
                this.$fly.get(`${sendCommandUrl}/${item.airportId}/${state}`).then(res => {
                    if (res.code != 0) {
                        return
                    }
                    if (state == 1) {
                        return;
                    }
                    //存储倒计时时间
                    CookieUtils.setCookikeByName(item.airportId, 60000, 60);
                    this.queryDrones();
                })
            },
            unique(arr) {
                return Array.from(new Set(arr))
            },
            async initPage() {
                if (this.socket) {
                    this.socket.close();
                    this.socket = null;
                }
                Promise.all([this.getFlightLog(), this.queryDrones()]).then(() => {
                    this.socket = initWs({
                        messageHandler: (res) => {
                            if (res.data === "心跳" || res.data === "连接开始") {
                                return;
                            }
                            let parseData = JSON.parse(res.data);
                            try {
                                const dataTypeHandlers = {
                                    droneCurrentInfo: () => {
                                        this.droneList.forEach((item, index) => {
                                            const data = parseData.data;
                                            if (data.dsn === item.droneSn) {
                                                //如果机场是开启状态,上次状态为范航,本次状态为等待飞行
                                                if (item.airportIsOpen == 0 && item.historState == 3 && data.s == 1) {
                                                    console.log('获取的Cook状态');
                                                    console.log(CookieUtils.getCookieByName(item.airportId));
                                                    if (!CookieUtils.getCookieByName(item.airportId) || CookieUtils.getCookieByName(item.airportId).length <= 0) {
                                                        CookieUtils.setCookikeByName(item.airportId, 60000, 60);
                                                    }
                                                    item.timeN = CookieUtils.getCookieByName(item.airportId);
                                                    console.log('设置计时时间');
                                                    console.log(item.timeN);


                                                    item.isClose = true;
                                                    let arr = CookieUtils.getCookieByName('isCloseList') || [];
                                                    arr.push(item.airportId);
                                                    arr = this.unique(arr);
                                                    CookieUtils.setCookikeByName('isCloseList', arr, 62);
                                                    //关闭机场
                                                    // this.operationAirport(item,1);
                                                }
                                                /**
                                                 * 当上次飞行状态为返航中下次的飞行状态也设置为返航中状态（因为飞行状态在无人机降落时会有一个偏差所以手动增加此判断修正）
                                                 * 无人机状态值:  0-未连接  1-等待飞行  2-飞行中  3-返航中  4-异常
                                                 * */

                                                item.droneState =
                                                    item.droneState === 3 && data.s === 2 ? 3 : data.s;
                                                item.h = data.h;
                                                item.b = data.b;
                                                item.sp = Math.abs(data.sp);

                                                //记录历史状态
                                                item.historState = item.droneState;
                                            }
                                            this.$set(this.droneList, index, item);
                                        });
                                    },
                                    droneFlightLog: () => {
                                        parseData.data.landingTime = dayjs(
                                            parseData.data.landingTime
                                        ).format("MM-DD HH:mm");
                                        parseData.data.takeOffTime = dayjs(
                                            parseData.data.takeOffTime
                                        ).format("MM-DD HH:mm");
                                        parseData.data.wayPonits = parseData.data.wayPoints;
                                        this.flightLogs.unshift(parseData.data);
                                    },
                                    droneShootPicture: async () => {
                                        for (let index in this.droneList) {
                                            const item = this.droneList[index];
                                            const data = parseData.data;
                                            let pictures = item.pictures;
                                            if (data.sn === item.droneSn) {
                                                if (!pictures) {
                                                    pictures = [];
                                                }
                                                const client = await this.createOss(
                                                    "oss-cn-beijing",
                                                    "cosmos-drones"
                                                );
                                                const pictureUrl = client.signatureUrl(data.picture);
                                                pictures.unshift(pictureUrl);
                                                if (pictures.length > 10) {
                                                    pictures.pop();
                                                }
                                            }
                                            this.$set(this.droneList[index], "pictures", pictures);
                                        }
                                    },
                                };
                                if (dataTypeHandlers.hasOwnProperty(parseData.method)) {
                                    dataTypeHandlers[parseData.method]();
                                }
                            } catch (err) {
                                console.log(err, "解析失败");
                            }
                        },
                    });
                });
            },
            async createOss(region, bucket) {
                const {oss, stsToken} = this.stsTokenStorage;
                if (stsToken && stsToken.expireTime > new Date().getTime()) {
                    return oss;
                }
                let res = await this.$fly.get("/account/staff/ossKey");
                let ossKeyData = res.data;
                const ossConfig = {
                        region,
                        accessKeyId: ossKeyData.accessKeyId,
                        accessKeySecret: ossKeyData.accessKeySecret,
                        bucket,
                        stsToken: ossKeyData.token,
                    },
                    aliOss = new OSS(ossConfig);
                this.stsTokenStorage = {
                    stsToken: ossKeyData,
                    oss: aliOss,
                };
                return aliOss;
            },
            // 返航降落
            cancelFlight(id) {
                // 加次判断是为了防止无人机在刚起飞后就被操作降落
                if (this.callbackCountdown > 0) {
                    return;
                }
                this.$fly.get(droneGoHomeUrl, {id});
                /*.then(res => {
                              if (res.code === 0) {
                                  this.queryDrones();
                              }
                          })*/
            },
            getFlightLog(pageNo = 1, pageSize = 6) {
                this.$fly
                    .post(queryFlightLogUrl, {
                        isLanding: 1,
                        regionCode: this.$vc.getCurrentRegion().code,
                        startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD 00:00"),
                        endDate: dayjs().format("YYYY-MM-DD 23:59"),
                        search: "",
                        pageNo,
                        pageSize,
                    })
                    .then((res) => {
                        if (res.data.datas) {
                            this.flightLogs = res.data.datas.map((item) => {
                                item.landingTime = dayjs(item.landingTime).format("MM-DD HH:mm");
                                item.takeOffTime = dayjs(item.takeOffTime).format("MM-DD HH:mm");
                                return item;
                            });
                        } else {
                            this.flightLogs = [];
                        }
                    });
            },
            operationDrone(droneInfo) {
                //防止多次点击
                if (this.callbackF) {
                    return
                }

                this.$fly
                    .get(startDroneFlightUrl, {
                        id: droneInfo.droneId,
                    })
                    .then((res) => {
                        if (res.code === 0) {
                            this.callbackCountdown = 10;
                            const timer = setInterval(() => {
                                this.callbackCountdown--;
                                if (this.callbackCountdown === 0) {
                                    clearInterval(timer);
                                }
                            }, 1000);
                            this.callbackF = true;
                            setTimeout(() => {
                                this.callbackF = false;
                                this.queryDrones();
                            }, 5000)


                        }
                    });
            },
            // 查询无人机
            queryDrones() {
                this.$fly
                    .get(queryDroneBigDataUrl, {
                        regionCode: this.$vc.getCurrentRegion().code,
                    })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        //回头

                        res.data = res.data.filter((item) => item.droneState !== 0);
                        for (let i = res.data.length; i < 3; i++) {
                            res.data.push({
                                droneState: 0,
                                wayPoints: "[]",
                                droneId: "droneId" + i,
                            });
                        }
                        setTimeout(() => {
                            res.data.forEach((item) => {
                                if (item.droneState === 0) {
                                    return;
                                }
                                const {rtsPullStreamAddr: liveUrl, flvPullStreamAddr: flvUrl} =
                                    item;
                                //阿里云播放器
                                new Aliplayer(
                                    {
                                        id: `drone_video_${item.droneId}`,
                                        source: isEdge ? flvUrl : liveUrl,
                                        width: "520px",
                                        height: "360px",
                                        autoplay: true,
                                        isLive: true,
                                        rePlay: false,
                                        playsinline: true,
                                        preload: true,
                                        controlBarVisibility: "never",
                                        useH5Prism: true,
                                        skinLayout: [{name: "bigPlayButton", align: "cc"}],
                                    },
                                    function (player) {
                                        // player.play();
                                        if (player) {
                                            player.setVolume(0);
                                            // console.log(
                                            //     player.getStatus(),
                                            //     "播放状态",
                                            //     "视频音量" + player.getVolume()
                                            // );
                                        }
                                    }
                                );
                            });
                        });
                        let arr = CookieUtils.getCookieByName('isCloseList') || [];
                        res.data = res.data.map(item => {
                            if (arr.includes(item.airportId)) {
                                return {
                                    ...item,
                                    timeN: CookieUtils.getCookieByName(item.airportId),
                                    isClose: true
                                }
                            }
                            return {
                                ...item,
                                timeN: CookieUtils.getCookieByName(item.airportId),
                                isClose: false
                            }
                        })
                        this.droneList = res.data;
                        console.log('当前状态');
                        console.log(res.data);
                    });
            },
            getFlvUrl(id) {
                return this.$fly
                    .get(getDroneRVCAddrUrl, {
                        id,
                    })
                    .then((res) => res.data);
            },
        },
    };
</script>

<style lang="stylus" scoped>
    .rowFlexCenter {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .data-group {
        @extend .rowFlexCenter;
        justify-content: center;

        &:not(:last-of-type) {
            margin-bottom: 32px;
        }
    }

    .data-item {
        width: 584px;
        height: 450px;

        &:not(:last-child) {
            margin-right: 38px;
        }

        &.video {
            background: url('../../assets/bigData/drones/bg.png');
            background-size: 100% 100%;
            position: relative;

            .title {
                font-size: 16px;
                color: #00FFF4;
                position: absolute;
                left: 50%;
                display: inline-block;
                transform: translateX(-50%);
                top: 20px;
            }

            .content {
                position: absolute;
                bottom: 20px;
                width: 520px;
                height: 360px;
                left: 50%;
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0);

                .drone-video {
                    width: 100%;
                    height: 100%;
                    position: relative;

                    .ali-player {
                        z-index: 0;
                    }
                }

                .status-panel {
                    position: absolute;
                    background: rgba(7, 65, 182, 0.2);
                    box-shadow: inset 0 0 8px 0 rgba(0, 255, 255, 0.8);
                    border-radius: 4px;
                    // border 1px solid
                    color: #fff;
                    padding: 5px 10px;
                    left: 10px;
                    top: 10px;
                    text-align: left;
                    font-size: 12px;

                    & > div {
                        &:not(:last-of-type) {
                            margin-bottom: 5px;
                        }

                        .status-label {
                            float: right;
                            padding: 4px 10px;
                            color: #09DEB3;
                            background: #C4FFF3;
                            border-radius: 58px;
                            margin-left: 10px;
                            line-height: 1;

                            &::after {
                                display: block;
                                content: ' ';
                            }
                        }
                    }
                }

                .flight-map {
                    width: 100px;
                    height: 70px;
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    background: rgba(7, 65, 182, 0.2);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .flight-img {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    width: 100px;
                    height: 260px;
                    background: rgba(7, 65, 182, 0.2);
                    box-shadow: inset 0 0 6px 0 rgba(0, 255, 255, 0.8);
                    overflow: hidden;
                    padding: 5px;
                    text-align: center;

                    img {
                        width: 90px;
                        height: 55px;
                        margin-bottom: 10px;
                    }
                }

                .drones-tip {
                    position: absolute;
                    left: 50%;
                    top: 102px;
                    transform: translateX(-50%);
                    background: url('../../assets/bigData/drones/drones-tip.png');
                    background-size: 100% 100%;
                    width: 238px;
                    height: 192px;
                    font-size: 30px;
                    line-height: 192px;
                    color: #1AC9FF;
                }
            }
        }

        &.control {
            @extend .rowFlexCenter;

            .container {
                width: 570px;
                height: 450px;
                background: rgba(7, 65, 182, 0.25);
                box-shadow: inset 0 0 40px 0 rgba(0, 255, 255, 0.4);
                padding: 20px;
                border-right: 1px solid #1ac9ff;

                .title {
                    position: relative;
                    margin-bottom: 20px;
                    font-size: 24px;
                    text-align: left;
                    color: #1AC9FF;
                    text-indent: 25px;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        content: ' ';
                        background: url('../../assets/bigData/drones/title-icon.png');
                        background-size: 100% 100%;
                        width: 15px;
                        height: 22px;
                    }
                }

                .content {
                    .drones-item {
                        @extend .rowFlexCenter;
                        background: url('../../assets/bigData/drones/drones-control.png');
                        background-size: 100% 100%;
                        width: 534px;
                        height: 102px;
                        padding: 0 20px;
                        box-sizing: border-box;
                        color: #1AC9FF;
                        justify-content: space-between;
                        font-size: 26px;

                        &:not(:last-of-type) {
                            margin-bottom: 28px;
                        }

                        & > div {
                            @extend .rowFlexCenter;

                            span {
                                line-height: 1;
                            }

                            .drone-img {
                                background: url('../../assets/bigData/drones/drone-bg.png');
                                width: 80px;
                                height: 80px;
                                background-size: 100% 100%;
                                margin-right: 10px;
                                position: relative;
                                padding: 4px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .flight-label {
                                padding: 4px 10px;
                                color: #09DEB3;
                                background: #C4FFF3;
                                font-size: 12px;
                                border-radius: 58px;
                                margin-left: 10px;
                                line-height: 1;
                            }
                        }

                        .flight-status {
                            background: url('../../assets/bigData/drones/flight-status.png');
                            background-size: 100% 100%;
                            padding: 8px 10px;
                            font-size: 20px;
                            min-width: 100px;
                            justify-content: center;
                        }

                        .flight-btn {
                            background: url('../../assets/bigData/drones/control-btn.png');
                            background-size: 100% 100%;
                            font-size: 20px;
                            width: 128px;
                            height: 52px;
                            justify-content: center;
                            transition: transform 0.4s 40ms;
                            cursor: pointer;
                            user-select: none;

                            &:active {
                                box-shadow: inset 5px 4px 20px rgba(0, 0, 0, 0.44);
                                // box-shadow inset 5px 4px 20px #fff
                                border-radius: 10px;
                            }

                            &.gray {
                                filter: grayscale(90%);

                                &:hover {
                                    transform: scale(1);
                                }
                            }

                            /* &:hover
                            transform scale(1.06) */
                        }
                    }
                }

                .flight-log-thead {
                    @extend .rowFlexCenter;
                    font-size: 14px;
                    color: #fff;
                    margin-bottom: 16px;
                    justify-content: space-around;
                    list-style: none;
                    padding: 0 10px;

                    li {
                        text-align: center;
                        flex: 0 0 auto;

                        img {
                            display: block;
                            margin: 0 auto;
                            margin-top: 5px;
                            width: 12px;
                        }

                        &:not(:last-of-type) {
                            margin-right: 24px;
                        }
                    }
                }

                .swiper-flight-log {
                    height: 310px;
                    overflow: hidden;

                    .swiper-slide {
                        height: unset;

                        &.insert {
                            animation: insetPassLog 0.4s cubic-bezier(0.47, 0, 0.745, 0.715) both;
                        }

                        &:not(:last-of-type) {
                            margin-bottom: 15px;
                        }
                    }

                    ul {
                        list-style: none;
                        font-size: 0;
                        position: relative;
                        overflow: hidden;
                        @extend .rowFlexCenter;
                        padding: 0 10px;
                        // @extends .rowFlex
                        // box-shadow inset 0 0 2.212vw 0 rgba(0, 255, 255, .2)
                        background: linear-gradient(180deg, rgba(39, 247, 225, 0.2) 0%, rgba(255, 255, 255, 0) 48%, rgba(39, 247, 225, 0.2) 100%);
                        transition: transform 0.4s 40ms;

                        &:hover {
                            transform: scale(1.06);
                        }

                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            width: 14px;
                            height: 100%;
                            content: ' ';
                            background: url('../../assets/bigData/1/zkh.png') no-repeat;
                            background-size: 100% 100%;
                            transform: translateY(-50%);
                        }

                        &::after {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 14px;
                            height: 100%;
                            content: ' ';
                            background: url('../../assets/bigData/1/ykh.png') no-repeat;
                            background-size: 100% 100%;
                            transform: translateY(-50%);
                        }

                        &:not(:last-of-type) {
                            margin-bottom: 25px;
                        }

                        li {
                            font-size: 12px;
                            color: #fff;
                            padding: 8px 10px;
                            line-height: 1;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            flex: 0 0 auto;
                            text-align: center;

                            &:not(:last-of-type) {
                                margin-right: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    .flight-btns {
        background: url('../../assets/bigData/drones/fanhaung.png');
        background-size: 100% 100%;
        font-size: 20px;
        width: 128px;
        height: 52px;
        justify-content: center;

        transition: transform 0.4s 40ms;
        cursor: pointer;
        user-select: none;
    }

    .noPoi {
        cursor: default;
    }
</style>
