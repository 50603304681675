<template>
    <div class="data-page-content rowFlex" style="margin-left: 8px; justify-content: center;">
        <div class="rowFlex">
            <div class="rowFlex column">
                <div class="data-item-wrap">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel meter">
                        <div class="top">
                            <div class="Instruments">
                                <svg class="icon" aria-hidden="true" style="font-size: 120px;">
                                    <use xlink:href="#icon-menuyibiaoshuyong"></use>
                                </svg>
                                <div class="num">{{ meterDosage.total }}个</div>
                                <!--假数据-->
                                <!--                <div class="num">35个</div>-->
                            </div>
                            <div class="waterElectric">
                                <div>
                                    <svg class="icon">
                                        <use xlink:href="#icon-menua-jianqu11"></use>
                                    </svg>
                                    <span class="water">水表：{{ meterDosage.waterTotal }}个</span>
                                </div>
                                <div>
                                    <svg class="icon">
                                        <use xlink:href="#icon-menudianbiao"></use>
                                    </svg>
                                    <span class="water">电表：{{ meterDosage.electricityTotal }}个</span>
                                    <!--                  <span class="electric">电表：20个</span>-->
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="water">
                                <svg class="icon">
                                    <use xlink:href="#icon-menuyongshuiliang"></use>
                                </svg>
                                <p class="text">昨日用水量</p>
                                <p class="num">{{ meterDosage.todayWaterTon }}t</p>
                                <!--                                假数据-->
                                <!--                <p class="num">192t</p>-->
                            </div>
                            <div class="electric">
                                <svg class="icon">
                                    <use xlink:href="#icon-menuyongdianliang"></use>
                                </svg>
                                <p class="text">昨日用电量</p>
                                <p class="num">{{ meterDosage.todayElectricityTon }}kWh</p>
                                <!--                <p class="num">2892kWh</p>-->
                            </div>
                        </div>
                    </div>
                </div>
                <!--        <div class="data-item-wrap">-->
                <!--          <DataItemPanelLeftBorder />-->
                <!--          <div class="personal-type-panel outdoor">-->
                <!--            <div class="data-item-title">室外实时环境数据</div>-->
                <!--            <div>-->
                <!--              <table border="1" class="environment-lable">-->
                <!--                <thead>-->
                <!--                  <tr>-->
                <!--                    <th>温度</th>-->
                <!--                    <th>湿度</th>-->
                <!--                    <th>风向</th>-->
                <!--                    <th>大气压</th>-->
                <!--                    <th>降雨量</th>-->
                <!--                    <th>风速</th>-->
                <!--                  </tr>-->
                <!--                </thead>-->
                <!--                <tbody>-->
                <!--                  <tr>-->
                <!--                    <td>{{ outDoorData.temperature }}°C</td>-->
                <!--                    <td>{{ outDoorData.humidity }}%</td>-->
                <!--                    <td>{{ outDoorData.windDirection }}°</td>-->
                <!--                    <td>{{ outDoorData.barometricPressure }}kPa</td>-->
                <!--                    <td>{{ outDoorData.precipitation }}mm</td>-->
                <!--                    <td>{{ outDoorData.windSpeed }}m/s</td>-->
                <!--                  </tr>-->
                <!--                </tbody>-->
                <!--              </table>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
                <div class="data-item-wrap">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel indoor">
                        <img :src="weatherArr[weatherIndex]" class="weateher" />
                    </div>
                </div>
            </div>
            <div class="rowFlex column">

                <div class="rowFlex top">
                    <div class="data-item-wrap">
                        <DataItemPanelLeftBorder />
                        <div class="personal-type-panel electricityTrend">
                            <div class="data-item-title">近30天用电走势</div>
                            <div id="electricityUseTrend"></div>
                        </div>
                    </div>
                    <div class="data-item-wrap">
                        <DataItemPanelLeftBorder />
                        <div class="personal-type-panel waterTrend">
                            <div class="data-item-title">近30天用水走势</div>
                            <div id="waterUseTrend"></div>
                        </div>
                    </div>
                </div>
                <div class="rowFlex">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel outdoorRealTime">
                        <div class="data-item-title">环境与能源消耗数据</div>
                        <div id="diagram"></div>
                    </div>
                </div>
                <div style="padding-top: 20px">
                    <div class="rowFlex">
                        <DataItemPanelLeftBorder />
                        <div class="personal-type-panel outdoorRealTime" style="height: 280px">
                            <div class="data-item-title" style="margin-bottom: 40px">室外实时环境数据</div>
                            <div style="display:flex">
                                <div class="out-wrapper" style="display: flex;width:600px;justify-content: space-between">
                                    <div class="out-wrapper-left">
                                        <div class="out-wrappe-item">
                                            <div class="img-hand">
                                                <div class="waiquan">
                                                    <div class="neiquan">
                                                        <img src="./images/wendu.png">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="yuanzhu">
                                                <img src="./images/yuanzhu.png">
                                            </div>

                                            <img src="./images/dizhuo.png" class="img-bottom">
                                            <div class="wen-title">
                                                <div class="biao">温度</div>
                                                <div>{{ outDoorTemperatureArr.temperature }}℃</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="out-wrapper-left">
                                        <div class="out-wrappe-item">
                                            <div class="img-hand">
                                                <div class="waiquan">
                                                    <div class="neiquan">
                                                        <img src="./images/shidu.png" style="width: 16px;left: 8px">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="yuanzhu">
                                                <img src="./images/yuanzhu.png">
                                            </div>

                                            <img src="./images/dizhuo.png" class="img-bottom">
                                            <div class="wen-title">
                                                <div class="biao">湿度</div>
                                                <div>{{ outDoorTemperatureArr.humidity }}%RH</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="out-wrapper-left">
                                        <div class="out-wrappe-item">
                                            <div class="img-hand">
                                                <div class="waiquan">
                                                    <div class="neiquan">
                                                        <img src="./images/fengxiang.png"
                                                            style="width: 18px;left: calc(50% - 9px)">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="yuanzhu">
                                                <img src="./images/yuanzhu.png">
                                            </div>

                                            <img src="./images/dizhuo.png" class="img-bottom">
                                            <div class="wen-title">
                                                <div class="biao">风向</div>
                                                <div>{{ outDoorTemperatureArr.windDirection }}°</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="out-wrapper-left">
                                        <div class="out-wrappe-item">
                                            <div class="img-hand">
                                                <div class="waiquan">
                                                    <div class="neiquan">
                                                        <img src="./images/fengsu.png"
                                                            style="width: 21px;left: calc(50% - 10px)">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="yuanzhu">
                                                <img src="./images/yuanzhu.png">
                                            </div>

                                            <img src="./images/dizhuo.png" class="img-bottom">
                                            <div class="wen-title">
                                                <div class="biao">风速</div>
                                                <div>{{ outDoorTemperatureArr.windSpeed }}m/s</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="out-wrapper-left">
                                        <div class="out-wrappe-item">
                                            <div class="img-hand">
                                                <div class="waiquan">
                                                    <div class="neiquan">
                                                        <img src="./images/daqiya.png"
                                                            style="width: 20px;left: calc(50% - 10px)">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="yuanzhu">
                                                <img src="./images/yuanzhu.png">
                                            </div>

                                            <img src="./images/dizhuo.png" class="img-bottom">
                                            <div class="wen-title">
                                                <div class="biao">大气压</div>
                                                <div>{{ outDoorTemperatureArr.barometricPressure }}kPa</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="env-right">
                                    <div class="env-right-title">绿化增长</div>
                                    <div id="environment">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--          <div class="rowFlex top">-->
                        <!--            <div class="data-item-wrap">-->
                        <!--              <DataItemPanelLeftBorder/>-->
                        <!--              <div class="personal-type-panel proportion">-->
                        <!--                <div class="data-item-title">近半年用电占比</div>-->
                        <!--                <div id="electricityUseProportion"></div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                        <!--            <div class="data-item-wrap">-->
                        <!--              <DataItemPanelLeftBorder/>-->
                        <!--              <div class="personal-type-panel proportion">-->
                        <!--                <div class="data-item-title">近半年用水占比</div>-->
                        <!--                <div id="meterUseProportion"></div>-->
                        <!--              </div>-->
                        <!--            </div>-->
                    </div>

                </div>

            </div>
            <div class="rowFlex column">
                <div class="data-item-wrap" style="margin-right: 0;">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel waterUseRanking">
                        <div class="data-item-title">近七天企业用水量排名</div>
                        <div id="waterUseRanking"></div>
                    </div>
                </div>
                <div class="data-item-wrap" style="margin-right: 0;">
                    <DataItemPanelLeftBorder />
                    <div class="personal-type-panel waterUseRanking">
                        <div class="data-item-title">近七天企业用电量排名</div>
                        <div id="electricUseRanking"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import DataItemPanelLeftBorder from "@/views/BigData/DataItemPanelLeftBorder";
import { initWs } from "@/views/BigData/tools";
import {
    queryCurrentUrl,
    queryBigDataOutdoorUrl,
    queryMeterCountUrl,
    queryBigDataIndoorUrl,
    queryHalfYearConsumeUrl,
    queryUserWaterTrendUrl,
    queryLesseeUseWaterUrl,
    queryLesseeUseElectricityUrl,
    queryBidDataEnvironmentAndElectricityUrl,
    queryUserElectricityTrendUrl,
    getOutDoorTemperatureUrl
} from '@/requestUrl'

let qing = require('./images/qingtian.png');
let yu = require('./images/yutian.png');
let xutian = require('./images/xuetian.png');
let yintian = require('./images/yintian.png');

let yuArr = ["LIGHT_RAIN", "MODERATE_RAIN", "HEAVY_RAIN", "STORM_RAIN"];
let xueArr = ["LIGHT_SNOW", "MODERATE_SNOW", "HEAVY_SNOW", "STORM_SNOW"];
let qingArr = ["CLEAR_DAY", "CLEAR_NIGHT", "WIND"];
let yinArr = [
    "PARTLY_CLOUDY_DAY",
    "PARTLY_CLOUDY_NIGHT",
    "CLOUDY",
    "LIGHT_HAZE",
    "MODERATE_HAZE",
    "HEAVY_HAZE",
    "DUST",
    "SAND",
];

let charts = [];
export default {
    name: 'BigDataPageFive',
    components: {
        DataItemPanelLeftBorder,
    },
    data() {
        return {
            weatherIndex: 0,//天气索引
            weatherArr: [yu, xutian, yintian, qing],
            environmentTimer: null,
            letIntive: 1,
            setTimer: null,
            socket: null,
            // 园区code
            regionCode: this.$vc.getCurrentRegion().code,
            // 水表数量及昨日消耗记录
            meterDosage: {},
            // 室外环境数据
            outDoorData: {},
            // 室内环境数据
            inDoorData: {},
            //环境数据
            outDoorTemperatureArr: {
                temperature: 0,//温度
                humidity: 0,//湿度
                windDirection: 0,//风向
                windSpeed: 0,//风速
                barometricPressure: 0,//大气压
            },
            // 室内环境label字段
            inDoorDataLabel: {
                "temperature": '温度',
                "barometricPressure": '大气压',
                "humidity": '湿度',
                "carbonDioxide": '二氧化碳',
                "illumination": 'PM2.5',
                "pmTen": 'PM10',
                "organics": 'TVOC',
                // "light": '光照',
                "ozone": '臭氧',
            },
            // 室内环境单位
            inDoorDataCompany: {
                "temperature": '℃',
                "barometricPressure": 'hPa',
                "humidity": '%',
                "carbonDioxide": 'ppm',
                "illumination": 'μg/m³',
                "pmTen": 'μg/m³',
                "organics": 'ppb',
                // "light": '级',
                "ozone": 'ppm',
            },
        }
    },
    created() {
        this.getgetOutDoorTemperature();
        this.getWeather();
    },
    mounted() {
        this.initPage();
        let beam = document.getElementsByClassName('yuanzhu');
        let letOption = 1;
        this.setTimer = setInterval(() => {
            letOption = letOption - this.letIntive;
            if (letOption <= 0 || letOption >= 1) {
                this.letIntive = -this.letIntive;
            }
            //环境闪
            // console.log(letOption);
            for (let i = 0; i < beam.length; i++) {
                beam[i].style.opacity = letOption;
            }
        }, 800)
    },
    beforeDestroy() {
        clearInterval(this.environmentTimer);
        charts.forEach(chart => chart.dispose());
        charts = [];
        if (this.socket) {
            this.socket.close(3000, '页面关闭');
        }
        clearInterval(this.setTimer);
    },
    methods: {
        //获取天气
        getWeather() {
            this.$fly.get(queryCurrentUrl, {
                regionCode: this.regionCode
            }).then(res => {
                if (res.code != 0) {
                    return
                }
                console.log('天气数据');
                console.log('weather', JSON.parse(res.data.weatherData));

                let weat = JSON.parse(res.data.weatherData);
                let weather = weat?.result?.realtime?.skycon || "";

                if (yuArr.includes(weather)) {
                    this.weatherIndex = 0
                } else if (xueArr.includes(weather)) {
                    this.weatherIndex = 1;
                } else if (yinArr.includes(weather)) {
                    this.weatherIndex = 2;
                } else if (qingArr.includes(weather)) {
                    this.weatherIndex = 3;
                } else {
                    this.weatherIndex = 3;
                }

            })
        },
        //获取室外实时环境数据
        getgetOutDoorTemperature() {
            this.$fly.get(getOutDoorTemperatureUrl, {
                regionCode: this.regionCode,
            }).then(res => {
                if (res.code != 0) {
                    return
                }
                console.log('asdasd');
                console.log(res);
                this.outDoorTemperatureArr = {
                    ...this.outDoorTemperatureArr,
                    ...res.data
                }
            })
        },

        //环境数据动态图
        getEnvironmentTree() {
            clearInterval(this.environmentTimer);
            const treeDataURI =
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAA2CAYAAADUOvnEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA5tJREFUeNrcWE1oE0EUnp0kbWyUpCiNYEpCFSpIMdpLRTD15s2ePHixnj00N4/GoyfTg2fbiwdvvagHC1UQ66GQUIQKKgn1UAqSSFua38b3prPJZDs7s5ufKn0w7CaZ2W/fe9/73kyMRqNB3Nrj1zdn4RJ6du9T2u1a2iHYSxjP4d41oOHGQwAIwSUHIyh8/RA8XeiXh0kLGFoaXiTecw/hoTG4ZCSAaFkY0+BpsZceLtiAoV2FkepZSDk5EpppczBvpuuQCqx0YnkYcVVoqQYMyeCG+lFdaGkXeVOFNu4aEBalOBk6sbQrQF7gSdK5JXjuHXuYVIVyr0TZ0FjKDeCs6km7JYMUdrWAUVmZUBtmRnVPK+x6nIR2xomH06R35ggwJPeofWphr/W5UjPIxq8B2bKgE8C4HVHWvg+2gZjXj19PkdFztY7bk9TDCH/g6oafDPpaoMvZIRI5WyMB/0Hv++HkpTKE0kM+A+h20cPAfN4GuRyp9G+LMTW+z8rCLI8b46XO9zRcYZTde/j0AZm8WGb3Y2F9KLlE2nqYkjFLJAsDOl/lea0q55mqxXcL7YBc++bsCPMe8mUyU2ZIpnCoblca6TZA/ga2Co8PGg7UGUlEDd0ueptglbrRZLLE7poti6pCaWUo2pu1oaYI1CF9b9cCZPO3F8ikJQ/rPpQT5YETht26ss+uCIL2Y8vHwJGpA96GI5mjOlaKhowUy6BcNcgIhDviTGWCGFaqEuufWz4pgcbCh+w0gEOyOjTlTtYYlIWPYWKEsLDzOs+nhzaO1KEpd+MXpOoTUgKiNyhdy5aSMPNVqxtSsJFgza5EWA4zKtCJ2OGbLn0JSLu8+SL4G86p1Fpr7ABXdGFF/UTD4rfmFYFw4G9VAJ9SM3aF8l3yok4/J6IV9sDVb36ynmtJ2M5+CwxTYBdKNMBaocKGV2nYgkz6r+cHBP30MzAfi4Sy+BebSoPIOi8PW1PpCCvr/KOD4k9Zu0WSH0Y0+SxJ2awp/nlwKtcGyHOJ8vNHtRJzhPlsHr8MogtlVtwUU0tSM1x58upSKbfJnSKUR07GVMKkDNfXpzpv0RTHy3nZMVx5IOWdZIaPabGFvfpwpjnvfmJHXLaEvZUTseu/TeLc+xgAPhEAb/PbjO6PBaOTf6LQRh/dERde23zxLtOXbaKNhfq2L/1fAOPHDUhOpIf5485h7l+GNHHiSYPKE3Myz9sFxoJuAyazvwIMAItferha5LTqAAAAAElFTkSuQmCC';
            const beginYear = 2016;
            const endYear = 2050;
            const lineCount = 10;

            // Make fake data.
            function makeCategoryData() {
                var categoryData = [];
                for (var i = 0; i < lineCount; i++) {
                    categoryData.push(i + 'a');
                }
                return categoryData;
            }

            function makeSeriesData(year, negative) {
                // make a fake value just for demo.
                const r = (year - beginYear + 1) * 10;
                const seriesData = [];
                for (let i = 0; i < lineCount; i++) {
                    let sign = negative ? -1 * (i % 3 ? 0.9 : 1) : 1 * ((i + 1) % 3 ? 0.9 : 1);
                    seriesData.push({
                        value:
                            sign *
                            (year <= beginYear + 1
                                ? Math.abs(i - lineCount / 2 + 0.5) < lineCount / 5
                                    ? 5
                                    : 0
                                : (lineCount - Math.abs(i - lineCount / 2 + 0.5)) * r),
                        symbolOffset: i % 2 ? ['50%', 0] : undefined
                    });
                }
                // console.log('seriesData');
                // console.log(seriesData);
                return seriesData;
            }

            // Set dynamic data.
            var currentYear = beginYear;
            let myChart = echarts.init(document.getElementById('environment'))
            this.environmentTimer = setInterval(function () {
                currentYear++;
                if (currentYear > endYear) {
                    currentYear = beginYear;
                }
                myChart.setOption({
                    xAxis: {
                        name: currentYear
                    },
                    series: [
                        {
                            data: makeSeriesData(currentYear)
                        },
                        {
                            data: makeSeriesData(currentYear, true)
                        }
                    ]
                });
            }, 800);
            return this.initEChart('environment', {
                color: ['#e54035'],
                xAxis: {
                    axisLine: { show: false },
                    axisLabel: { show: false },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    name: beginYear + '',
                    nameLocation: 'middle',
                    nameGap: 0,
                    nameTextStyle: {
                        fontSize: 0,
                    },
                    min: -2800,
                    max: 2800
                },
                yAxis: {
                    data: makeCategoryData(),
                    show: false
                },
                grid: {
                    top: 'center',
                    height: 60
                },
                series: [
                    {
                        name: 'all',
                        type: 'pictorialBar',
                        symbol: 'image://' + treeDataURI,
                        symbolSize: [15, 27],
                        symbolRepeat: true,
                        data: makeSeriesData(beginYear),
                        animationEasing: 'elasticOut'
                    },
                    {
                        name: 'all',
                        type: 'pictorialBar',
                        symbol: 'image://' + treeDataURI,
                        symbolSize: [15, 27],
                        symbolRepeat: true,
                        data: makeSeriesData(beginYear, true),
                        animationEasing: 'elasticOut'
                    }
                ]
            })
        },


        // 获取水电标数及昨日用量
        async getMeterDosage() {
            let res = await this.$fly.get(queryMeterCountUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            console.log('仪表数');
            console.log(res.data);
            this.meterDosage = res.data;
        },
        // 室外实时环境数据
        async getOutDoorData() {
            let res = await this.$fly.get(queryBigDataOutdoorUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.outDoorData = res.data;
        },
        // 室内实时环境数据
        async getInDoorData() {
            let res = await this.$fly.get(queryBigDataIndoorUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return;
            }
            this.inDoorData = res.data;
        },
        // 获取近半年用电走势
        // getElectricityTrend() {
        //   return this.initEChart('electricityUseTrend', {
        //     grid: {
        //       top: 8,
        //       left: 0,
        //       right: 0,
        //       bottom: 0,
        //       containLabel: true
        //     },
        //     xAxis: {
        //       type: 'category',
        //       data: ['6月', '7月', '8月', '9月', '10月', '11月'],
        //       axisLine: {
        //         lineStyle: {
        //           color: '#fff',
        //         }
        //       },
        //       axisLabel: {
        //         color: '#fff',
        //       }
        //     },
        //     yAxis: {
        //       type: 'value',
        //       max: 80000,
        //       axisLabel: {
        //         formatter: '{value}kWh',
        //         color: '#fff'
        //       }
        //     },
        //     series: [
        //       {
        //         // 假数据
        //         data: [68000, 72000, 62000, 56000, 43000, 55000],
        //         type: 'line'
        //       }
        //     ]
        //   })
        //   // })
        // },
        //近30天用电走势
        async getElectricityTrend() {

            let res = await this.$fly.get(queryUserElectricityTrendUrl, {
                regionCode: this.regionCode,
            })
            if (res.code != 0) {
                return
            }
            console.log('电表');
            console.log(res.data);
            let lineData = [];
            let barData = [];

            barData = res.data.map(item => {
                return +item.idelRate
            })
            lineData = barData.map(item => {
                return +item
            })


            return this.initEChart('electricityUseTrend', {
                grid: {
                    top: 8,
                    left: 0,
                    right: 0,
                    bottom: 8,
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    data: [],
                    textStyle: {
                        color: '#ccc'
                    }
                },
                xAxis: {
                    data: [],
                    axisLine: {
                        lineStyle: {
                            color: '#ccc',
                        }
                    }
                },
                yAxis: {
                    type: 'value',

                    axisLine: {
                        lineStyle: {
                            color: '#ccc'
                        }
                    },
                    axisLabel: {
                        formatter: '{value}kWh',
                        color: '#fff'
                    }
                },
                series: [

                    {
                        name: 'bar',
                        type: 'bar',
                        barWidth: 4,
                        itemStyle: {
                            borderRadius: 5,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#CF8CDB' },
                                { offset: 1, color: '#358FE8' }
                            ])
                        },
                        data: barData
                    },
                    {
                        name: 'line',
                        type: 'bar',
                        barGap: '-100%',
                        barWidth: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                { offset: 0, color: 'rgba(20,200,212,0)' },
                                { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                                { offset: 1, color: 'rgba(20,200,212,0)' }
                            ])
                        },
                        z: -12,
                        data: lineData
                    },

                ]
            })
            // })
        },
        // 获取近半年用水走势
        getWaterTrend() {
            return this.$fly.get(queryUserWaterTrendUrl, {
                regionCode: this.regionCode,
            })
                .then(res => {
                    if (res.code != 0) {
                        return;
                    }
                    console.log('大数据用水量排名');
                    console.log(res.data);
                    let monthList = res.data.map(item => {
                        return item.month.slice(5) + '月';
                    })
                    let idelRateList = res.data.map(item => {
                        return item.idelRate;
                    })
                    let barData = [];
                    let lineData = [];
                    barData = res.data.map(item => {
                        return +item.idelRate
                    })
                    lineData = barData.map(item => {
                        return +item
                    })
                    return this.initEChart('waterUseTrend', {
                        grid: {
                            top: 8,
                            left: 0,
                            right: 0,
                            bottom: 8,
                            containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            }
                        },
                        legend: {
                            data: [],
                            textStyle: {
                                color: '#ccc'
                            }
                        },
                        xAxis: {
                            data: [],
                            axisLine: {
                                lineStyle: {
                                    color: '#fff',
                                }
                            }
                        },
                        yAxis: {
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}t',
                                color: '#fff'
                            },
                            axisLine: {
                                lineStyle: {
                                    color: '#fff'
                                }
                            },
                        },
                        series: [

                            {
                                name: 'bar',
                                type: 'bar',
                                barWidth: 4,
                                itemStyle: {
                                    borderRadius: 5,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        { offset: 0, color: '#CF8CDB' },
                                        { offset: 1, color: '#358FE8' }
                                    ])
                                },
                                data: barData
                            },
                            {
                                name: 'line',
                                type: 'bar',
                                barGap: '-100%',
                                barWidth: 10,
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                        { offset: 0, color: 'rgba(20,200,212,0)' },
                                        { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                                        { offset: 1, color: 'rgba(20,200,212,0)' }
                                    ])
                                },
                                z: -12,
                                data: barData
                            },
                        ]
                    })
                })
        },
        // 近半年用电占比
        getElectricityUseProportion() {
            // return this.$fly.get(, {
            //
            // })
            // .then(res => {
            //     if (res.code != 0) {
            //         return;
            //     }
            return this.initEChart('electricityUseProportion', {
                tooltip: {
                    trigger: 'item'
                },
                color: ['#F35914', '#F6CF00', '#F6C701', '#F6BF03', '#F5B704', '#F5B005', '#F5A807', '#F5A008', '#F59809', '#F4900B', '#F4880C', '#F4800D', '#F4780F', '#F47110', '#F36911', '#F36113'],
                series: [
                    {
                        name: '近半年用电占比',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        label: {
                            formatter: '{b}\n{d} %',
                            color: '#BC921F',
                        },
                        data: [
                            { value: 12, name: '1号楼' },
                            { value: 15, name: '2号楼' },
                            { value: 25, name: '3号楼' },
                            { value: 36, name: '4号楼' },
                            { value: 12, name: '5号楼' },
                        ]
                    }
                ]
            })
            // })
        },
        // 近半年用水占比
        getMeterUseProportion() {
            return this.$fly.get(queryHalfYearConsumeUrl, {
                regionCode: this.regionCode,
            })
                .then(res => {
                    if (res.code != 0) {
                        return
                    }
                    let data = res.data.map(item => {
                        let waterProportion = {
                            value: item.percentage,
                            name: item.building,
                        }
                        return waterProportion;
                    })
                    return this.initEChart('meterUseProportion', {
                        tooltip: {
                            trigger: 'item'
                        },
                        color: ['#2678F3', '#60D9A3', '#5CD3A8', '#58CCAE', '#50C4B1', '#51BFB8', '#4DB9BE', '#49B2C3', '#45ACC8', '#41A5CE', '#3D9FD3', '#3998D8', '#3592DE', '#328BE3', '#2E85E8', '#2A7EEE'],
                        series: [
                            {
                                name: '近半年用水占比',
                                type: 'pie',
                                radius: ['40%', '70%'],
                                label: {
                                    formatter: '{b}\n{d} %',
                                    color: '#4F9BE0',
                                },
                                // data
                                //    假数据
                                data: [
                                    { value: 16, name: '1号楼' },
                                    { value: 11, name: '2号楼' },
                                    { value: 31, name: '3号楼' },
                                    { value: 30, name: '4号楼' },
                                    { value: 12, name: '5号楼' },
                                ]
                            }
                        ]
                    })
                })
        },
        // 环境与好耗电实时关系图
        getDiagram() {
            return this.$fly.get(queryBidDataEnvironmentAndElectricityUrl, {
                regionCode: this.regionCode,
            })
                .then(res => {
                    if (res.code != 0) {
                        return
                    }
                    let maxTemperatureList = [];
                    let minTemperatureList = [];
                    let timeList = [];
                    let consumptionList = []
                    console.log(res.data, 55555)
                    res.data.forEach(item => {
                        maxTemperatureList.push(item.maxTemperature);
                        minTemperatureList.push(item.minTemperature);
                        timeList.push(item.date.slice(5));
                        consumptionList.push(item.consumption);
                    })
                    return this.initEChart('diagram', {
                        color: ['#F6BD16', '#F35914', '#5AAEF3'],
                        grid: {
                            top: 30,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            containLabel: true
                        },
                        legend: {
                            textStyle: {
                                color: '#fff'
                            },
                            data: ['用电量', '室外最高温度', '室外最低温度'],
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: timeList,
                                axisPointer: {
                                    type: 'shadow',
                                },
                                axisLabel: {
                                    color: '#fff',
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                min: 0,
                                // max: 4000,
                                minInterval: 1000,
                                splitLine: {
                                    show: false
                                },
                                splitNumber: 4,//分割成几段
                                axisLabel: {
                                    formatter: '{value} kWh',
                                    color: '#fff',
                                },
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: '#5AAEF3'
                                    }
                                }
                            },
                            {
                                type: 'value',
                                min: -20,
                                max: 60,
                                interval: 20,
                                axisLabel: {
                                    formatter: '{value} °C',
                                    color: '#fff'
                                },
                                axisLine: {
                                    lineStyle: {
                                        color: '#5AAEF3'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '用电量',
                                type: 'bar',
                                data: consumptionList
                            },
                            {
                                name: '室外最高温度',
                                type: 'line',
                                yAxisIndex: 1,
                                data: maxTemperatureList
                            },
                            {
                                name: '室外最低温度',
                                type: 'line',
                                yAxisIndex: 1,
                                data: minTemperatureList
                            }
                        ]
                    })
                })
        },
        // 获取企业近七天用水排名
        getWaterUseRanking() {
            this.$fly.get(queryLesseeUseWaterUrl, {
                regionCode: this.regionCode,
            })
                .then(res => {
                    if (res.code != 0) {
                        return;
                    }
                    console.log('用水排名');
                    console.log(res.data);
                    res.data = res.data.filter(item => {
                        return item.consumption > 0
                    })
                    let lesseeName = [];
                    let lesseeWater = [];
                    res.data.forEach(item => {
                        let companyName = "";
                        //小于4个 前后各显示一个
                        if (item.companyName.length <= 4) {
                            let sum_ = "";
                            for (let i = 0; i < item.companyName.length - 2; i++) {
                                sum_ += '∗';
                            }
                            companyName = item.companyName.slice(0, 1) + sum_ + item.companyName.slice(item.companyName.length - 1);
                        } else if (item.companyName.length <= 8) {
                            //小于8个隐藏中间两个
                            let start = "";
                            if (item.companyName.length % 2 == 0) {
                                start = item.companyName.length / 2 - 1;
                            } else {
                                start = Math.trunc(item.companyName.length / 2);
                            }
                            companyName = item.companyName.slice(0, start) + '∗∗' + item.companyName.slice(start + 2);
                        } else {
                            companyName = item.companyName.slice(0, 3) + '∗∗∗∗' + item.companyName.slice(item.companyName.length - 4);
                        }
                        lesseeName.push(companyName);
                        lesseeWater.push(item.consumption);
                    })
                    return this.initEChart('waterUseRanking', {
                        grid: {
                            top: 0,
                            left: 0,
                            right: 50,
                            bottom: -50,
                            containLabel: true
                        },
                        xAxis: {
                            show: false
                        },
                        yAxis: {
                            type: 'category',
                            axisLabel: { interval: 0, rotate: 0 },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#fff'
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            data: lesseeName,
                            inverse: true,
                            max: 10,
                        },
                        series: {
                            type: 'bar',
                            encode: { y: 'name', x: 'score' },
                            datasetIndex: 1,
                            // barWidth: 14,
                            // data: lesseeWater,
                            // 假数据
                            data: lesseeWater,
                            label: {
                                show: true,
                                position: 'right',
                                color: '#fff',
                                formatter: '{c}t'
                            }
                        },
                        color: {
                            type: 'linear',
                            x: 1,
                            y: 1,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: 'rgba(148, 253, 235, 0.8)' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(91, 119, 249, 0.8)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    })
                })
        },
        // 获取企业近七天用电排名
        getElectricUseRanking() {
            return this.$fly.get(queryLesseeUseElectricityUrl, {
                regionCode: this.$vc.getCurrentRegion().code
            })
                .then(res => {
                    if (res.code != 0) {
                        return
                    }
                    console.log('用水排名222');
                    console.log(res.data);
                    let lesseeName = [];
                    let lesseeWater = [];
                    res.data = res.data.filter(item => {
                        return item.consumption > 0
                    })
                    res.data.forEach(item => {
                        let companyName = "";
                        //小于4个 前后各显示一个
                        if (item.companyName.length <= 4) {
                            let sum_ = "";
                            for (let i = 0; i < item.companyName.length - 2; i++) {
                                sum_ += '∗';
                            }
                            companyName = item.companyName.slice(0, 1) + sum_ + item.companyName.slice(item.companyName.length - 1);
                        } else if (item.companyName.length <= 8) {
                            //小于8个隐藏中间两个
                            let start = "";
                            if (item.companyName.length % 2 == 0) {
                                start = item.companyName.length / 2 - 1;
                            } else {
                                start = Math.trunc(item.companyName.length / 2);
                            }
                            companyName = item.companyName.slice(0, start) + '∗∗' + item.companyName.slice(start + 2);
                        } else {
                            companyName = item.companyName.slice(0, 3) + '∗∗∗∗' + item.companyName.slice(item.companyName.length - 4);
                        }
                        lesseeName.push(companyName);
                        lesseeWater.push(item.consumption);
                    })
                    return this.initEChart('electricUseRanking', {
                        grid: {
                            top: 0,
                            left: 0,
                            right: 50,
                            bottom: -50,
                            containLabel: true
                        },

                        xAxis: {
                            show: false,

                        },
                        yAxis: {
                            type: 'category',
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#fff',

                                }
                            },
                            axisLabel: {
                                interval: 0,
                                rotate: 0,
                                // verticalAlign:'bottom'
                            },

                            // fontSize:'30',
                            // textVerticalAlign: 'bottom',
                            // align:'bottom',
                            // textStyle: {
                            //     rich: {
                            //         rich: {
                            //             a:{
                            //
                            //             }
                            //         }
                            //     }
                            // },
                            data: lesseeName,
                            inverse: true,
                            axisTick: {
                                show: false
                            },
                            max: 10,
                        },
                        series: {
                            type: 'bar',
                            encode: { y: 'name', x: 'score' },
                            datasetIndex: 1,
                            // barWidth: 14,
                            data: lesseeWater,
                            label: {
                                show: true,
                                position: 'right',
                                color: '#fff',
                                formatter: '{c}kWh'
                            }
                        },
                        color: {
                            type: 'linear',
                            x: 1,
                            y: 1,
                            x2: 0,
                            y2: 0,
                            colorStops: [{
                                offset: 0, color: 'rgba(230, 196, 25, 1)' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(230, 128, 6, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    })
                })
        },
        async initPage() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
            Promise.all([
                this.getElectricityTrend(),
                this.getWaterTrend(),
                // this.getElectricityUseProportion(),
                // this.getMeterUseProportion(),
                this.getDiagram(),
                this.getWaterUseRanking(),
                this.getElectricUseRanking(),
                this.getMeterDosage(),
                this.getOutDoorData(),
                this.getInDoorData(),
                //环境数据
                this.getEnvironmentTree(),
            ])
                .then(allRes => {
                    const [electricityTrendChart, waterTrendChart,
                        electricityUseProportionChart,
                        diagramChart, waterUseRankingChart, electricUseRankingChart,
                    ] = allRes;
                    // console.log(allRes);
                    this.socket = initWs({
                        messageHandler: res => {
                            if (res.data == '心跳') {
                                return;
                            }

                            try {
                                let data = JSON.parse(res.data);
                                const dataTypeHandler = {
                                    meterCount: () => {
                                        this.meterDosage = data.data.data;
                                    },
                                    bigDataOutdoor: () => {
                                        this.outDoorData = data.data.data;
                                    },
                                    bigDataIndoor: () => {
                                        this.inDoorData = data.data.data;
                                    },
                                    //室外环境数据
                                    outdoorEnvironment: () => {
                                        this.outDoorTemperatureArr = data.data;
                                    },
                                    //天气
                                    weatherDataEnvironment: () => {
                                        console.log('天气');
                                        let weat = JSON.parse(res.data.weatherData);
                                        let weather = weat?.result?.realtime?.skycon || "";

                                        if (yuArr.includes(weather)) {
                                            this.weatherIndex = 0
                                        } else if (xueArr.includes(weather)) {
                                            this.weatherIndex = 1;
                                        } else if (yinArr.includes(weather)) {
                                            this.weatherIndex = 2;
                                        } else if (qingArr.includes(weather)) {
                                            this.weatherIndex = 3;
                                        } else {
                                            this.weatherIndex = 3;
                                        }
                                    },
                                    //近半年用电走势
                                    useElectricityTrend: () => {
                                        let idelRateList = data.data.data.map(item => {
                                            return item.idelRate;
                                        })
                                        electricityTrendChart.setOption({
                                            xAxis: {
                                                data: [],
                                                axisLine: {
                                                    lineStyle: {
                                                        color: '#ccc',
                                                    }
                                                }
                                            },
                                            series: [
                                                {
                                                    name: 'line',
                                                    type: 'line',
                                                    smooth: true,
                                                    showAllSymbol: true,
                                                    symbol: 'emptyCircle',
                                                    symbolSize: 5,
                                                    data: idelRateList,
                                                    itemStyle: {
                                                        color: '#00FFEE'
                                                    }
                                                },
                                                {
                                                    name: 'bar',
                                                    type: 'bar',
                                                    barWidth: 4,
                                                    itemStyle: {
                                                        borderRadius: 5,
                                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            { offset: 0, color: '#CF8CDB' },
                                                            { offset: 1, color: '#358FE8' }
                                                        ])
                                                    },
                                                    data: idelRateList
                                                },
                                                {
                                                    name: 'line',
                                                    type: 'bar',
                                                    barGap: '-100%',
                                                    barWidth: 10,
                                                    itemStyle: {
                                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                                            { offset: 0, color: 'rgba(20,200,212,0)' },
                                                            { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                                                            { offset: 1, color: 'rgba(20,200,212,0)' }
                                                        ])
                                                    },
                                                    z: -12,
                                                    data: idelRateList
                                                },
                                            ]
                                        })
                                    },
                                    useWaterTrend: () => {
                                        let monthList = data.data.data.map(item => {
                                            return item.month.slice(5) + '月';
                                        })
                                        let idelRateList = data.data.data.map(item => {
                                            return item.idelRate;
                                        })
                                        waterTrendChart.setOption({
                                            xAxis: {
                                                data: [],
                                                axisLine: {
                                                    lineStyle: {
                                                        color: '#ccc',
                                                    }
                                                }
                                            },
                                            series: [
                                                {
                                                    name: 'line',
                                                    type: 'line',
                                                    smooth: true,
                                                    showAllSymbol: true,
                                                    symbol: 'emptyCircle',
                                                    symbolSize: 5,
                                                    data: idelRateList,
                                                    itemStyle: {
                                                        color: '#00FFEE'
                                                    }
                                                },
                                                {
                                                    name: 'bar',
                                                    type: 'bar',
                                                    barWidth: 4,
                                                    itemStyle: {
                                                        borderRadius: 5,
                                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                            { offset: 0, color: '#CF8CDB' },
                                                            { offset: 1, color: '#358FE8' }
                                                        ])
                                                    },
                                                    data: idelRateList
                                                },
                                                {
                                                    name: 'line',
                                                    type: 'bar',
                                                    barGap: '-100%',
                                                    barWidth: 10,
                                                    itemStyle: {
                                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 0, [
                                                            { offset: 0, color: 'rgba(20,200,212,0)' },
                                                            { offset: 0.2, color: 'rgba(20,200,212,0.2)' },
                                                            { offset: 1, color: 'rgba(20,200,212,0)' }
                                                        ])
                                                    },
                                                    z: -12,
                                                    data: idelRateList
                                                },
                                            ]
                                        })
                                    },
                                    //近七天用电量
                                    lesseeUseElectricity: () => {
                                        // let lesseeName = [];
                                        // let lesseeWater = [];
                                        // data.data.forEach(item => {
                                        //   let companyName = item.companyName.slice(0, 3) + '****' + item.companyName.slice(item.companyName.length - 4);
                                        //   lesseeName.push(companyName);
                                        //   lesseeWater.push(item.consumption);
                                        // })
                                        data.data.data = data.data.data.filter(item => {
                                            return item.consumption > 0
                                        })
                                        let source = data.data.data.map(item => {
                                            let companyName = item.companyName.slice(0, 3) + '****' + item.companyName.slice(item.companyName.length - 4);
                                            return [companyName, item.consumption];
                                        })
                                        waterUseRankingChart.setOption({
                                            dataset: [
                                                {
                                                    dimensions: ['name', 'score'],
                                                    source
                                                },
                                                {
                                                    transform: {
                                                        type: 'sort',
                                                        config: { dimension: 'score', order: 'asc' }
                                                    }
                                                }
                                            ],
                                            yAxis: {
                                                type: 'category',
                                                axisLabel: { interval: 0, rotate: 0 },
                                                axisLine: {
                                                    show: false,
                                                    lineStyle: {
                                                        color: '#fff'
                                                    }
                                                },
                                                axisTick: {
                                                    show: false
                                                },
                                                max: source.length,
                                            },
                                        })

                                    },
                                    bigDataIndoorHour: () => {
                                        let temperatureList = [];
                                        let timeList = [];
                                        for (let key in data.data.data) {
                                            temperatureList.push(data.data.data[key]);
                                            let time = key.slice(key.length - 2);
                                            timeList.push(time + ':00');
                                        }
                                        diagramChart.setOption({
                                            xAxis: [
                                                {
                                                    type: 'category',
                                                    data: timeList,
                                                    axisPointer: {
                                                        type: 'shadow',
                                                    },
                                                    axisLabel: {
                                                        color: '#fff',
                                                    }
                                                }
                                            ],
                                            series: [
                                                {
                                                    name: '用电量',
                                                    type: 'bar',
                                                    data: [
                                                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                                                    ]
                                                },
                                                {
                                                    name: '室外温度',
                                                    type: 'line',
                                                    yAxisIndex: 1,
                                                    data: temperatureList,
                                                }
                                            ]
                                        })
                                    },
                                    halfYearConsume: () => {
                                        // let data = data.data.data.map(item => {
                                        //   let waterProportion = {
                                        //     value: item.percentage,
                                        //     name: item.building,
                                        //   }
                                        //   return waterProportion;
                                        // })
                                        // meterUseProportionChart.setOption({
                                        //   series: [
                                        //     {
                                        //       name: '近半年用水占比',
                                        //       type: 'pie',
                                        //       radius: ['40%', '70%'],
                                        //       label: {
                                        //         formatter: '{b}\n{d} %',
                                        //         color: '#4F9BE0',
                                        //       },
                                        //       data
                                        //     }
                                        //   ]
                                        // })
                                    },
                                    lesseeUseWater: () => {
                                        data.data.data = data.data.data.filter(item => {
                                            return item.consumption > 0
                                        })
                                        let source = data.data.data.map(item => {
                                            let companyName = item.companyName.slice(0, 3) + '****' + item.companyName.slice(item.companyName.length - 4);
                                            return [companyName, item.consumption];
                                        })
                                        waterUseRankingChart.setOption({
                                            dataset: [
                                                {
                                                    dimensions: ['name', 'score'],
                                                    source
                                                },
                                                {
                                                    transform: {
                                                        type: 'sort',
                                                        config: { dimension: 'score', order: 'asc' }
                                                    }
                                                }
                                            ],
                                            yAxis: {
                                                type: 'category',
                                                axisLabel: { interval: 0, rotate: 0 },
                                                axisLine: {
                                                    show: false,
                                                    lineStyle: {
                                                        color: '#fff'
                                                    }
                                                },
                                                axisTick: {
                                                    show: false
                                                },
                                                max: source.length,
                                            },
                                        })
                                    }
                                }
                                dataTypeHandler[data.method]();
                            } catch (err) {
                                console.log('解析失败', err);
                            }
                        }
                    })
                })
        },
        initEChart(elName, options) {
            const myChart = echarts.init(document.getElementById(elName));
            myChart.setOption(options);
            charts.push(myChart);
            return myChart;
        },
    }
}
</script>

<style lang="stylus" scoped>
    @import "./common.styl";

    .column
        flex-flow column
        align-items flex-start

    .data-item-wrap
        @extends .rowFlex
        margin-right 20px

        &:not(:last-of-type)
            margin-bottom 20px

    .personal-type-panel
        overflow hidden
        background rgba(7, 65, 182, 0.25)
        box-shadow inset 0 0 50px 0 rgba(0, 255, 255, .2)
        padding 20px
        border-right 1px solid #1ac9ff

    .meter
        width 440px
        height 330px

        .top
            display flex
            align-items center

            .Instruments
                width 120px
                height 90px
                position relative

                &:before
                    content '仪表数'
                    color #1987F1
                    position absolute
                    font-size 18px
                    position absolute
                    z-index 10
                    left 50%
                    transform translateX(-50%)
                    bottom 0
                    white-space nowrap

                .num
                    color #FFD301
                    font-size 24px
                    position absolute
                    z-index 10
                    top 48%
                    left 50%
                    transform translate(-50%, -50%)
                    white-space nowrap

            .waterElectric
                margin-left 20px

                div
                    svg
                        font-size 30px
                        vertical-align middle
                        margin-right 10px

                    &:not(:last-of-type)
                        margin-bottom 25px

                    .water
                        font-size 24px
                        color #0D9AFF
                        vertical-align middle

                    .electric
                        font-size 24px
                        color #F6BD16
                        vertical-align middle

        .bottom
            margin-top 20px
            display flex
            justify-content space-around

            .water
                text-align center
                color #0D9AFF

                svg
                    font-size 60px

                .text
                    font-size 24px
                    margin-bottom 0

                .num
                    font-size 34px
                    margin-bottom 0

            .electric
                @extends .water
                color #F6BD16

    .environment-lable
        width 380px
        border 1px solid #fff
        color #fff
        text-align center
        height 100px
        font-size 14px

        thead
            tr
                th
                    height 49px

    .indoor-table
        @extends .environment-lable
        thead
            tr
                th
                    height 32px

        tbody
            tr
                td
                    height 32px

    .outdoor
        width 440px
        height 190px

    .indoor
        width 440px
        height 580px

    .electricityTrend
        width 440px
        height 300px

    .waterTrend
        @extends .electricityTrend

    .proportion
        width 440px
        height 280px

    .outdoorRealTime
        width 910px
        height 310px

    .waterUseRanking
        width 430px
        height 455px

    #electricityUseTrend
        height 230px

    #waterUseTrend
        height 230px

    #electricityUseProportion
        height 210px

    #meterUseProportion
        height 210px

    #diagram
        height 230px

    #waterUseRanking
        height 370px

    #electricUseRanking
        height 370px

    .env-right-title
        color #ffffff
        width 120px
        height 30px
        line-height 30px
        margin 0 auto
        font-size 20px
        color #1AC9FF
        background-image url("./images/lvhuaBg.png")
        background-size 100% 100%

    .env-right
        width 270px
        height 230px

    #environment
        width 230px
        margin 0 auto
        height 140px

    .out-wrappe-item
        position relative
        width 80px

        .wen-title
            padding-top 50px
            font-size 16px
            text-align center
            font-weight 500
            color #ffffff

            .biao
                color: #0D9AFF;

        .yuanzhu
            transition opacity .8s
            position absolute
            let 0
            top 15px

            img
                width 100%
                left 0
            width 80px
            height 120px

        img
            position absolute

        .img-hand
            width 40px
            position absolute
            top 0
            left calc(50% - 20px)

            div
                position absolute

            .waiquan
                width 40px
                height 40px
                background-color rgba(26, 201, 255, 0.1);
                box-shadow inset 0px 0px 10px 0px #1AC9FF
                border-radius 50%

            .neiquan
                border-radius 50%
                width 30px
                height 30px
                left calc(50% - 15px);
                top calc(50% - 15px)
                background: linear-gradient(135deg, rgba(26, 201, 255, 0.5) 0%, rgba(26, 201, 255, 0) 50%, rgba(26, 201, 255, 0.4) 100%);

                img
                    width 12px
                    left calc(50% - 6px)
                    top calc(50% - 11px)

        .img-bottom
            width 80px
            left 0
            top 120px

    .weateher
        width 100%
        height 100%
</style>
