<template>
  <div class="data-view-wrap">
    <!-- <div class="full-screen-bar" v-show="!isFullscreen" @click="fullScreen">
              <svg class="icon" aria-hidden="true" >
                <use :xlink:href="isFullscreen ? '#icon-menusuoxiao' : '#icon-menuziyuanldpi'"></use>
              </svg>
            </div> -->
    <div id="full-screen">
      <div class="data-view">
        <div class="data-view-header">
            <div class="left" @click="prevPage">
                <div>
                    <svg class="icon">
                        <use xlink:href="#icon-menuxingzhuangjiehe"></use>
                    </svg>
                </div>
            </div>
          {{ pageTitle }}
            <div class="left" @click="nextPage">
                <div>
                    <svg class="icon">
                        <use xlink:href="#icon-menuxingzhuangjiehe"></use>
                    </svg>
                </div>
            </div>
        </div>
        <BigDataPageOne v-if="containKey(1) === true && currentPageIndex === containVal(1)" />
        <BigDataPageTwo v-if="containKey(2) === true && currentPageIndex === containVal(2)" />
        <BigDataPageThree v-if="containKey(3) === true && currentPageIndex === containVal(3)" />
        <BigDataPageFour v-if="containKey(4) === true && currentPageIndex === containVal(4)" />
        <BigDataPageFive v-if="containKey(5) === true && currentPageIndex === containVal(5)" />
        <BigDataPageSix v-if="containKey(6) === true && currentPageIndex === containVal(6)" />
        <BigDataPageSeven v-if="containKey(7) === true && currentPageIndex === containVal(7)" />

      </div>
      <div class="full-screen-bar" v-show="!isFullscreen">
        <svg
          class="icon iconSize"
          aria-hidden="true"
          @click="fullScreen"
          style="opacity: 1"
        >
          <use
            :xlink:href="
              isFullscreen ? '#icon-menua-jianqu1' : '#icon-menua-jianqu1'
            "
          ></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import BigDataPageOne from "@/views/BigData/BigDataPageOne";
import BigDataPageTwo from "@/views/BigData/BigDataPageTwo";
import BigDataPageThree from "@/views/BigData/BigDataPageThree";
import BigDataPageFour from "@/views/BigData/BigDataPageFour";
import BigDataPageFive from "@/views/BigData/BigDataPageFive";
import BigDataPageSix from "@/views/BigData/BigDataPageSix";
import BigDataPageSeven from "@/views/BigData/BigDataPageSeven";
import {getBigDataConfigUrl} from "@/requestUrl";

let pageIndexMapping = {
    //隐藏无人机与机器人和科技物业
  1: "园区综合信息大数据",
  2: "飞控巡航",
  3: "陆地巡视",
  4: "智慧园区",
  5: "能耗大数据看板",
  6: "用户运营",
  7: "科技物业"
};


export default {
  name: "BigDataPage",
  components: {
    BigDataPageOne,
    BigDataPageTwo,
    BigDataPageThree,
    BigDataPageFour,
    BigDataPageFive,
    BigDataPageSix,
    BigDataPageSeven,
  },
  data() {
    return {
      currentPageIndex: 1,
      pageTitle: "",
      isFullscreen: false,
      fullScreenBarVisible: false,
      checkItem:[],
      showPage:{}
    };
  },
  async created() {
    /*const swapPageIndexMapping = {};
        Object.keys(pageIndexMapping).map(key => {

            swapPageIndexMapping[pageIndexMapping[key]] = key;
        })*/
      await this.getBigDataConfig()
      console.log("第一页-----")
      console.log(this.showPage)
      console.log(this.currentPageIndex)
      console.log(this.containVal(1))
      console.log(this.containKey(1))
    this.pageTitle = pageIndexMapping[this.currentPageIndex];
    /*this.currentPageIndex = swapPageIndexMapping[this.$route.name];*/
  },
  mounted() {
    this.judgeZoom();
    window.addEventListener("keydown", (e) => {
      switch (e.code) {
        case "ArrowRight":
          this.nextPage();
          break;
        case "ArrowLeft":
          this.prevPage();
          break;
      }
      // e.stopPropagation();
    });
    this.initRatio();
    let resizeTimer;
    window.addEventListener("resize", (e) => {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {
        this.initRatio();
      }, 100);
    });
    this.fullScreenListener();
  },
  methods: {
    initRatio() {
      const isFirefox = navigator.userAgent
        .toLocaleUpperCase()
        .includes("FIREFOX");
      const fullViewEl = document.querySelector("#full-screen"),
          viewEl = document.querySelector(".data-view"),
        warpEl = document.querySelector(".data-view-wrap");
      const defaultWidth = 1920,
        defaultHeight = 1080;
      let ratio;
      if (document.fullscreenElement) {
          const widthRatio = isFirefox ?  window.screen.width / warpEl.clientWidth : window.screen.width / defaultWidth,
              heightRatio =  isFirefox ? window.screen.width / warpEl.clientHeight : window.screen.height / defaultHeight;
          ratio = (widthRatio < heightRatio ? widthRatio : heightRatio ).toFixed(
              2
          );
          if (isFirefox) {
              warpEl.style.transform = "unset";
              viewEl.style.transformOrigin = "top left";
              viewEl.style.position = "absolute";
              viewEl.style.transform = `scale(${ratio}) translate(${-50}%, ${-50}%)`;
              viewEl.style.top = `${50}%`;
              viewEl.style.left = `${50}%`;
          } else {
              warpEl.style.zoom = ratio;
              viewEl.style.zoom = "normal";
          }
      } else {
          const parent = document.getElementsByClassName("wrapper-content")[0];
          parent.style.minHeight = '100%';
          const widthRatio =
                  (parent.clientWidth - 20) / warpEl.clientWidth,
              heightRatio = (parent.clientHeight - 60) / warpEl.clientHeight;
          ratio = (widthRatio < heightRatio ? widthRatio : heightRatio).toFixed(
              2
          );
          if (isFirefox) {
              warpEl.style.transformOrigin = "top left";
              warpEl.style.transform = "scale(" + ratio + ")";
              viewEl.style.transform = "unset";
              viewEl.style.transformOrigin = "unset";
              viewEl.style.position = "static";
          } else {
              viewEl.style.zoom = "normal";
              warpEl.style.zoom = ratio;
          }
      }
    },
    fullScreenListener() {
      const fullViewEl = document.getElementById("full-screen"),
        viewEl = document.getElementsByClassName("data-view")[0];
      window.addEventListener("keydown", (e) => {
        if (e.key === "F11") {
          if (!document.fullscreenElement) {
            viewEl.style.margin = "0 auto";
            fullViewEl.requestFullscreen();
          } else {
            if (document.exitFullscreen) {
              viewEl.style.margin = "unset";
              document.exitFullscreen();
              this.isFullscreen = false;
              console.log(this.isFullscreen);
            }
          }
          e.preventDefault();
        }
        if ("Escape" === e.key && document.fullscreenElement) {
          viewEl.style.margin = "unset";
        }
      });
      // this.isFullscreen = true;
    },
    fullScreen() {
      const fullViewEl = document.querySelector("#full-screen");
      fullViewEl.requestFullscreen();
      this.isFullscreen = true;
    },
    prevPage() {
        console.log(pageIndexMapping)
      if (this.currentPageIndex > 1) {
        this.currentPageIndex--;
      } else {
          //隐藏科技物业
        // this.currentPageIndex = 7;
          this.currentPageIndex = this.checkItem.length;
      }
      this.pageTitle = pageIndexMapping[this.currentPageIndex];
      // this.$router.push({name: pageIndexMapping[this.currentPageIndex]});
    },
    nextPage() {
        //隐藏无人机机器人和
        // this.currentPageIndex < 7
      if (this.currentPageIndex < this.checkItem.length) {
        this.currentPageIndex++;
      } else {
        this.currentPageIndex = 1;
      }
      this.pageTitle = pageIndexMapping[this.currentPageIndex];
      // this.$router.push({name: pageIndexMapping[this.currentPageIndex]});
    },
    //判断是否全屏
    judgeZoom() {
      var thant = this;
      document.addEventListener("fullscreenchange", function (e) {
        if (document.fullscreenElement) {
        } else {
          setTimeout(() => {
            thant.isFullscreen = false;
          }, 100);
        }
      });
    },
    async getBigDataConfig() {
          await this.$fly.get(getBigDataConfigUrl, {regionCode: this.$vc.getCurrentRegion().code}).then(res => {
              if (res.code != 0) {
                  return
              }
              this.checkItem = res.data;
              pageIndexMapping = {
                  //隐藏无人机与机器人和科技物业
                  1: "园区综合信息大数据",
                  2: "飞控巡航",
                  3: "陆地巡视",
                  4: "智慧园区",
                  5: "能耗大数据看板",
                  6: "用户运营",
                  7: "科技物业"
              };
              let changePageIndexMapping = {};
              let key = 1;
              for (let pageIndexMappingKey in pageIndexMapping) {
                  let index = this.checkItem.indexOf(pageIndexMapping[pageIndexMappingKey]);
                  if (index > -1){
                      this.showPage[pageIndexMappingKey] = key;
                      changePageIndexMapping[key] =  pageIndexMapping[pageIndexMappingKey];
                      key++;
                  }
              }
              pageIndexMapping = changePageIndexMapping;
          })
      },
      containKey(item){
          for (let page in this.showPage) {
              if (page == item){
                  console.log(112223333)
                  console.log(this.showPage.keys)
                  return true;
              }
          }
          return false;
      },
      containVal(item){
          for (let page in this.showPage) {
              if (page == item){
                  return  this.showPage[page];
              }
          }
          return -1;
      },
  },
};
</script>

<style lang="stylus" scoped>
#full-screen {
  position: relative;
  overflow: hidden;
}

#full-screen:hover {
  .full-screen-bar {
    transform: translateY(0px);
  }
}

.data-view {
  background: #000839;
  // width: 1920px;
  //width: 100%;
  height: 1080px;
  overflow: hidden;
  position: relative;
  text-align: center;

  &-header {
    position: relative;
    text-align: center;
    height: 98px;
    width: 1896px;
    margin: 0 auto;
    color: #fff;
    font-size: 30px;
    padding-top: 20px;
    box-sizing: border-box;
    background: no-repeat url('../../assets/bigData/1/title.png') left top;
    background-size: 100% 100%;

    & > .left {
      position: absolute;
      width: 60px;
      height: 60px;
      top: 30px;
      border-radius: 50%;
      background-color: #2C6DFF;
      // transform translateY(-50%)
      cursor: pointer;
      transition: transform 0.4s 40ms;
      box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.3);
      &:hover {
        transform: scale(1.2);
      }

      &:first-of-type {
        left: 228px;
        & > div > svg {
            position: absolute;
            left: 47%;
            top: 50%;
            transform translate(-50%, -50%);
        }
      }

      &:last-of-type {
        right: 228px;
        & > div {
            width: 100%;
            height: 100%;
            transform: rotate(180deg);
            & > svg {
                position: absolute;
                left: 47%;
                top: 50%;
                transform translate(-50%, -50%);
            }
        }
      }

    }
  }

  &-wrap {
    box-shadow: 0 0.177vw 0.354vw 0 rgba(0, 0, 0, 0.2);
    padding: 30px;
    width: 1980px;
    background: #fff;

    .full-screen-bar {
      width: 1920px;
      height: 50px;
      padding: 10px 20px;
      box-sizing: border-box;
      // background rgba(255, 255, 255, .7)
      font-size: 1.504vw;
      text-align: left;
      line-height: 1;
      cursor: pointer;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50px);
      transition: all 0.5s;
    }
  }
}

.iconSize {
  font-size: 30px;
  float: right;
}
</style>
