<template>
    <div class ='timer-container'>
        <template v-if="textT">
            {{text3}}
        </template>
        <template v-else>
            {{textN?textN:text1}}
        </template>

    </div>
</template>

<script>
    import {sendCommandUrl} from "@/requestUrl";
    import CookieUtils from "@/utils/CookieUtils";

    export default {
        name: "TimerText",
        props:['tiem','text1','id','text2','item','state','text3'],
        data(){
            return{
                timeOut:null,
                textN:'',
                textT:'',
                timeOut2:''
            }
        },
        mounted() {
            if(this.text3){
                clearInterval(this.timeOut2);
                this.textT = this.text3;
                this.timeOut2 = setTimeout(() => {
                    this.textT = '';
                    this.timeS();
                },2000)
            }else{
                this.timeS();
            }

        },
        methods:{
            operationAirport(item, state) {
                this.$fly.get(`${sendCommandUrl}/${item}/${state}`).then(res => {
                    if (res.code != 0) {
                        return
                    }
                })
            },
            timeS(){
                clearInterval(this.timeOut);
                this.ti = this.tiem;
                console.log('计时时间');
                console.log(this.ti);
                if(this.state&&this.id){
                    this.operationAirport(this.id,this.state);
                }

                this.timeOut = setInterval(() => {
                    this.ti -= 1000;
                    if(this.ti <= 0){
                        if(this.state&&this.id){
                            setTimeout(() =>{
                                this.$emit('timeOutFund',{id:this.id,state:1})
                            },2000)
                        }else{
                            setTimeout(() =>{
                                this.$emit('timeOutFun',this.id)
                            },2000)
                        }

                        this.textN = this.text2
                        clearInterval(this.timeOut);
                    }
                },1000)
            }
        },
        beforeDestroy() {
            clearInterval(this.timeOut);
            //更新倒计时时间
            CookieUtils.setCookikeByName(item, this.ti, 60);
        }
    }
</script>

<style scoped>

</style>